<template>
  <div style="height: 100%">
    <!-- 卡片视图 -->
    <el-card style="height: 100%;overflow:auto;">
      <!-- 顶部面包屑导航 -->
      <breadcrumb-nav>
        <template v-slot:firstMenu>计划管理</template>
        <template v-slot:secondMenu>计划列表</template>
      </breadcrumb-nav>
      <div style="fontSize: 20px;fontWeight: 400;color: #0a0b1a;">
        计划列表
      </div>
      <div class="radioBox">
        <el-radio-group v-model="sole" @change="agreeChange">
          <el-radio-button :label=1 :class="sole==1?'activeRadioButtonBox':'radioButtonBox'">审批中</el-radio-button>
          <el-radio-button :label=3 :class="sole==3?'activeRadioButtonBox':'radioButtonBox'">已审批</el-radio-button>
          <el-radio-button :label=2 :class="sole==2?'activeRadioButtonBox':'radioButtonBox'">已取消</el-radio-button>
        </el-radio-group>
      </div>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="选择计划日期">
          <el-date-picker
            v-model="formInline.user"
            type="daterange"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
            value-format="yyyy/MM/dd"
            @change="onsubmit"
            >
          </el-date-picker>
        </el-form-item>
        <el-form-item label="贸易企业">
          <el-select v-model="formInline.region" clearable placeholder="请选择贸易企业" @change="enterpriseChoose">
            <el-option
                    v-for="item in factoryList"
                    :key="item.tardId"
                    :label="item.tardName"
                    :value="item.tardId">
                  </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="液源">
          <el-select v-model="formInline.region1" clearable placeholder="请选择液源" @change="onsubmit">
            <el-option
                    v-for="item in LngList"
                    :key="item.sourceId"
                    :label="item.sourceName"
                    :value="item.sourceName">
                  </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div class="buttonBox">
        <plan-add style="marginRight: 10px" @plan-list="updateRoleList"/>
        <el-button type="primary"  @click="ArrangePlan()">导出</el-button>
      </div>
      <!-- table表格区域 -->
      <plan-table :plan-list='goodsList' :sole="sole" @plan-list="updateRoleList"/>
      <div style="float: right;">
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            :current-page="queryInfo.pageNum"
            :page-sizes="[10, 20, 30, 50]"
            :page-size="queryInfo.pageSize"
            layout="total, sizes, prev, pager, next, jumper"
            :total="total">
        </el-pagination>
      </div>
    </el-card>
  </div>
</template>

<script>
  import BreadcrumbNav from "../common/BreadcrumbNav";
  // import RecordPriceModal from './modal/recordPriceModal';
  import PlanTable from './PlanComponents/PlanTable'

  import { getPlansListRequest,excelPlan,getEnterpriseRequest,getLngRequest,getLngSourceRequest,excelPlanList } from "network/plan";
  import PlanAdd from './PlanComponents/PlanAdd';


  export default {
    name: "List",
    components: {
      PlanTable,
      BreadcrumbNav,
      PlanAdd
    },
    data() {
      return {
         sole: 1,
        queryInfo: {
          pageNum: 1,
          pageSize: 10
        },
        formInline: {
          user: '',
          region: '',
          region1: ''
        },
        goodsList: [],
        total: 0,
        factoryList: [],
        LngList: [],
        queryTime: {},
      }
    },
    created() {
      this.getGoodsList();
      this.getFactory();
      this.getLngList()
    },
    methods: {
      getGoodsList() {
        getPlansListRequest({
          ...this.queryInfo,
          sole: this.sole,
          tardId: this.formInline.region,
          sourceName: this.formInline.region1,
          ...this.queryTime
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取计划数据失败', 'error');
          }
          this.goodsList = result.data.list;
          this.total = result.data.total;
        });
      },

      getFactory(){
        getEnterpriseRequest({
          stationId: localStorage.getItem('enterpriseId')
        }).then(res => {
          let result = res.data;
          if (result.code !== 200) {

          }
          this.factoryList = result.data;
        })
      },

      getLngList (){
        getLngRequest({
          stationId: localStorage.getItem('enterpriseId')
        }).then(res=>{
          let result = res.data;
          this.LngList = result.data
        })
      },

      getLngLists(value) {
      getLngSourceRequest({
        tardId: value
      }).then(res=> {
        let result = res.data
        this.LngList = result.data
      })
    },

      enterpriseChoose(value){
        this.formInline.region1 = ''
        if(value==''){
          this.getLngList()
        }else{
          this.getLngLists(value)
        }
    },

      onsubmit(){
        console.log(this.formInline.user);
        if(this.formInline.user){
          this.queryTime.startDate = this.formInline.user[0],
          this.queryTime.endDate = this.formInline.user[1]
        }else{
          this.queryTime ={}
        }
        this.getGoodsList()
      },

      ArrangePlan(){
        excelPlanList({
          sole: this.sole
        }).then(res=>{
          var content = res.headers['content-disposition'];
            var name = content && content.split(';')[1].split('filename=')[1];
            var fileName = decodeURIComponent(name)
            this.downloadCallback(res, fileName);
        })
      },

        //生成下载文件
    downloadCallback(res, fileName){
      const content = res.data;
      const blob = new Blob([content]);
      if ("download" in document.createElement("a")) {
        // 非IE下载
        const elink = document.createElement("a");
        elink.download = fileName;
        elink.style.display = "none";
        elink.href = URL.createObjectURL(blob);
        document.body.appendChild(elink);
        elink.click();
        URL.revokeObjectURL(elink.href); // 释放URL 对象
        document.body.removeChild(elink);
      } else {
        // IE10+下载
        navigator.msSaveBlob(blob, fileName);
      }
    },

      // 每页显示的数据条数发送变化
      handleSizeChange(newSize) {
        this.queryInfo.pageNum = 1
        this.queryInfo.pageSize = newSize;
        this.getGoodsList();
      },

      // 当前页码发生变化
      handleCurrentChange(newPage) {
        this.queryInfo.pageNum = newPage;
        this.getGoodsList();
      },

      updateRoleList(){
        this.getGoodsList()
      },

      agreeChange(val){
        this.queryInfo.pageNum=1
        this.queryInfo.pageSize = 10
        this.getGoodsList()
      },
    }
  }
</script>

<style scoped>

.radioBox{
  margin-top: 30px;
  margin-bottom: 15px;
}
.buttonBox{
  display: flex;
}
.el-button{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}

.radioButtonBox{
  background-color: var(--theme_bg_color);
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
  border-radius: 4px 4px 4px 4px;
}
.el-radio-button :hover{
  color: var(--theme_bg_color);
}
.activeRadioButtonBox :hover{
  color: #fff;
  box-shadow: 0px 0 0 0 var(--theme_bg_color) !important;
}
.activeRadioButtonBox.el-radio-button__orig-radio.el-radio-button__inner{
  box-shadow: 0px !important;
}
.buttonBox{
  display: flex;
}

.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}


</style>
