<!--
 * @Description: 
 * @Author: 
 * @Date: 2023-04-23 10:08:09
 * @LastEditors: 
 * @LastEditTime: 2023-05-16 10:14:58
-->
<template>
  <el-button type="text"  @click="removeRoleById(roleId)">取消审批</el-button>
</template>

<script>
  import {editPlan} from 'network/plan'

  export default {
    name: "PlanCancel",
    props: {
      roleId: {
        type: Number,
        required: true
      }
    },
    methods: {
      // 根据ID删除用户
      removeRoleById(id) {
        this.$confirm('此操作将取消该审批信息, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          editPlan({
            planId: this.roleId,
            estimate: 2
          }).then(res => {
            let result = res.data;
            if (result.code !== 200) {
              return this.alertMessage('取消失败', 'error');
            }
            this.alertMessage('取消审批成功', 'success');
            this.$emit('plan-list');
          });
        }).catch(() => {
          this.alertMessage('已取消删除','info');
        });
      }
    }
  }
</script>

<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
</style>