<template>
<div>
    <el-row>
      <el-col>
        <el-button type="text"  @click="editPlans(planId)">{{ sole==1?"编辑":'编辑并提交审核' }}</el-button>
      </el-col>
    </el-row>

  <el-dialog
            title="编辑计划"
            :visible.sync="setRightDialogVisible"
            :append-to-body='true'
            :modal-append-to-body='true'
            width="50%">
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="150px" class="demo-ruleForm">
        <el-form-item label="计划日期" required prop="date1">
              <el-date-picker type="date" placeholder="选择日期" v-model="ruleForm.date1" style="width: 300px;" value-format="yyyy-MM-dd"></el-date-picker>
        </el-form-item>
        <el-form-item label="贸易企业" prop="region">
          <el-select v-model="ruleForm.region" placeholder="请选择贸易企业" style="width: 300px;" @change="enterpriseChoose">
            <el-option
                    v-for="item in enterpriseList"
                    :key="item.tardId"
                    :label="item.tardName"
                    :value="item.tardId">
                  </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="液源" prop="region2">
          <el-select v-model="ruleForm.region2" placeholder="请选择液源" style="width: 300px;">
            <el-option
                    v-for="item in lngList"
                    :key="item.sourceName"
                    :label="item.sourceName"
                    :value="item.sourceName">
                  </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="期望到站时间" required prop="date2">
            <el-date-picker v-model="ruleForm.date2" type="datetime" placeholder="选择日期时间" style="width: 300px;" value-format="yyyy-MM-dd HH:mm:ss"></el-date-picker>
        </el-form-item>
        <el-form-item label="配送方式" prop="type">
          <el-select v-model="ruleForm.type" placeholder="请选择配送方式" style="width: 300px;">
            <el-option label="配送" value="2"></el-option>
            <el-option label="自提" value="1"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="ruleForm.type==2" label="配送地址" prop="address" style="width: 300px;">
          <el-input v-model="ruleForm.address" placeholder="请输入配送地址" ></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.type==2" label="联系人" prop="people" style="width: 300px;">
          <el-input v-model="ruleForm.people" placeholder="请输入联系人" ></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.type==2" label="电话" prop="photo" style="width: 300px;">
          <el-input v-model="ruleForm.photo" placeholder="请输入电话" ></el-input>
        </el-form-item>
        <el-form-item v-if="ruleForm.type==1" label="车辆信息" >
          <el-row>
            <el-col :span="11">
              <el-form-item >
                <el-select v-model="ruleForm.purchasePlanVo[0].tractor" placeholder="请选择牵引车">
                  <el-option
                    v-for="item in tractorList"
                    :key="item.tractorInformation"
                    :label="item.tractorInformation"
                    :value="item.tractorInformation">
                    <!-- <span style="float: left">{{ item.tractorNumber }}</span>
                    <span style="float: right; color: #8492a6; font-size: 13px">{{ item.status }}</span> -->
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            
            <el-col :span="11">
              <el-form-item >
                <el-select v-model="ruleForm.purchasePlanVo[0].trailer" placeholder="请选择挂车">
                  <el-option
                    v-for="item in trailerList"
                    :key="item.trailerInformation"
                    :label="item.trailerInformation"
                    :value="item.trailerInformation">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
          <el-row style="marginTop: 20px">
            <el-col :span="11">
              <el-form-item >
                <el-select v-model="ruleForm.purchasePlanVo[0].driver" placeholder="请选择司机">
                  <el-option
                    v-for="item in driverList"
                    :key="item.driverInformation"
                    :label="item.driverInformation"
                    :value="item.driverInformation">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="11">
              <el-form-item >
                <el-select v-model="ruleForm.purchasePlanVo[0].escort" placeholder="请选择押运员">
                  <el-option
                    v-for="item in escortList"
                    :key="item.escortInformation"
                    :label="item.escortInformation"
                    :value="item.escortInformation">
                  </el-option>
                </el-select>
              </el-form-item>
            </el-col>
          </el-row>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="resetForm('ruleForm')">取 消</el-button>
        <el-button type="primary" @click="submitForm('ruleForm')">确 定</el-button>
      </span>
    </el-dialog>
    </div>
</template>
<script>
import { getPlanInfoRequest,editPlan,getEnterpriseRequest,getLngSourceRequest } from "network/plan"
import {getEscortRequest,getDriverRequest,getTrailerRequest,getTractorRequest } from 'network/order'


export default {
  name: "PlanAdd",
  props: {
    planId: {
      type: Number,
    },
    sole: {
      type: Number
    }
  },
  data() {
    var checkPhone = (rule, value, callback) => {
    let regPone = null
    let mobile = /^((13|14|15|17|18)[0-9]{1}\d{8})$/ // 最新16手机正则
    let tel = /^((0\d{2,3}-\d{7,8})|(1[3584]\d{9}))$/ // 座机
    if (value !== null && value.charAt(0) === '0') { // charAt查找第一个字符方法，用来判断输入的是座机还是手机号
      regPone = tel
    } else if (value !== null && value.charAt(0) !== '0') {
      regPone = mobile
    }
    if (regPone === null) {
      return callback(
        new Error('请输入电话')
      )
    } else if (!regPone.test(value)) {
      return callback(
        new Error("请输入正确的电话格式,其中座机格式'区号-座机号码'")
      )
    } else {
      callback()
    }
  }
    return {
      property: 'value',
      setRightDialogVisible: false,
      ruleForm: {
          address:'',
          people: '',
          photo: '',
          region: '',
          region2: '',
          date1: '',
          date2: '',
          type: '',
          purchasePlanVo: [
            {
              tractor: '',
              trailer: '',
              driver: '',
              escort: '',
            }
          ],
        },
        rules: {
        address: [
          { required: true,message: '请输入配送地址',trigger: 'blur' }
        ],
        people: [
          { required: true,message: '请输入联系人姓名',trigger: 'blur' }
        ],
        photo: [
          { required: true,message: '请输入电话',trigger: 'blur' },
          { validator: checkPhone, trigger: "blur" }
        ],
        region: [
          { required: true, message: '请选择活动区域', trigger: 'change' }
        ],
        region2: [
          { required: true,message: '请选择液厂', trigger: 'change'}
        ],
      
        date1: [
          { required: true, message: '请选择日期', trigger: 'change' }
        ],
        date2: [
          { required: true, message: '请选择时间', trigger: 'change' }
        ],
        },
      tractorList: [],
      trailerList: [],
      driverList: [],
      escortList: [],
      enterpriseList: [],
      lngList: []
      
            
    };
  },
  created() {
      
    },
  methods: {
    getList(){
      this.getTractorList()
      this.getTrailerList()
      this.getDriverList()
      this.getEscortList()
      this.getEnterpriseList()
    },

    // 获取贸易企业
    getEnterpriseList(){
      getEnterpriseRequest({
        stationId: localStorage.getItem('enterpriseId')
      }).then(res=>{
        console.log(res,485);
        let result = res.data
        if(result.code !== 200 ) {

        }
        this.enterpriseList = result.data
      })
    },

    // 获取牵引车
    getTractorList(){
        getTractorRequest({}).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.tractorList = result.data;
        });
      },

      // 获取挂车
      getTrailerList(){
        getTrailerRequest({}).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.trailerList = result.data;

        });
      },

      // 获取司机
      getDriverList(){
        getDriverRequest({}).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.driverList = result.data;
        });
      },

      // 获取押运员
      getEscortList(){
        getEscortRequest({}).then(res => {
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取列表数据失败', 'error');
          }
          this.escortList = result.data;

        });
      },

      enterpriseChoose(value){
      this.getLngList(value)
      this.ruleForm.region2 = ''
    },

    getLngList(value) {
      getLngSourceRequest({
        tardId: value
      }).then(res=> {
        let result = res.data
        if(result.code !== 200 ) {

        }
        this.lngList = result.data
      })
    },
    editPlans(id) {
      this.setRightDialogVisible = true
      this.getList();
      getPlanInfoRequest({
        planId: id
        }).then(res => {
          console.log(res);
          let result = res.data;
          if (result.code !== 200) {
            return this.alertMessage('获取计划详情失败', 'error');
          }
          this.ruleForm = {
          address:result.data.address,
          people: result.data.userName,
          photo: result.data.phone,
          region: result.data.tardId,
          region2: result.data.sourceName,
          date1: result.data.planDate,
          date2: result.data.arrivalDate,
          type: result.data.distributionType.toString(),
          purchasePlanVo: [
            {
              tractor: result.data.tractorNumber,
              trailer: result.data.trailerNumber,
              driver: result.data.driverInfo,
              escort: result.data.escortInfo,
            }
          ],
          }
          this.getLngList(result.data.tardId)
        });
    },
    submitForm(formName) {
        this.$refs.ruleForm.validate((valid) => {
          if (valid) {
            editPlan({
                planId:this.planId,
                planDate: this.ruleForm.date1,
                tardId: this.ruleForm.region,
                estimate: 1,
                sourceName: this.ruleForm.region2,
                arrivalDate: this.ruleForm.date2,
                distributionType: this.ruleForm.type,
                address: this.ruleForm.address,
                userName: this.ruleForm.people,
                phone: this.ruleForm.photo,
                purchasePlanVo: this.ruleForm.purchasePlanVo,
              }).then(res=>{
                let result = res.data;
                if (result.code !== 200) {
                  return this.alertMessage(result.msg, 'error');
                }
                this.alertMessage('修改成功', 'success');
                this.$emit('plan-list');
                // 隐藏弹窗
                this.setRightDialogVisible=false
                this.$refs.ruleForm.resetFields();
                
              })
          } else {
            console.log('error submit!!');
            return false;
          }
        });
      },
      resetForm(formName) {
        this.setRightDialogVisible=false
        this.$refs[formName].resetFields();
      }
  }
}
</script>
<style scoped>
.el-button--text{
  color: var(--theme_bg_color);
}
  .el-button:focus,
  .el-button:hover {
    border-color: var(--theme_bg_color);
    color: var(--theme_bg_color);
  }
.el-button--primary{
  background-color: var(--theme_bg_color);
  border: 1px solid var(--theme_bg_color)
}
.el-button--primary:focus,
  .el-button--primary:hover {
    border-color: var(--theme_bg_color);
    color: #fff;
  }
</style>
