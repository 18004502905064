<!--
 * @Description:
 * @Author:
 * @Date: 2023-02-27 15:54:08
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-05-17 15:19:36
-->
<template>
    <el-table  :data="planList" border
              :header-cell-style="{color: '#848484', fontSize: '14px', height:'22px',  background: '#f7f7f7'}"
              :cell-style="{color: '#0a0b1a'}"
              ref="table">
        <el-table-column prop="planDate" label="计划日期" width="200px"></el-table-column>
        <el-table-column prop="planNumber" label="计划编号" width="120px"></el-table-column>
        <el-table-column prop="enterpriseName" label="贸易企业名称" width="120px"></el-table-column>
        <el-table-column prop="distributionTypeName" label="配送方式" width="120px"></el-table-column>
        <el-table-column prop="tractorNumber" label="车辆信息" width="120px">
            <template slot-scope="scope">
                <span>{{ scope.row.tractorNumber ? scope.row.tractorNumber : "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="address" label="配送地" width="120px">
            <template slot-scope="scope">
                {{ scope.row.distributionType == 1 ? '--' : scope.row.address }}
            </template>
        </el-table-column>
        <el-table-column prop="driverInfo" label="司机信息" width="120px">
            <template slot-scope="scope">
                <span>{{ scope.row.driverInfo ? scope.row.driverInfo : "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="escortInfo" label="押送员信息" width="120px">
            <template slot-scope="scope">
                <span>{{ scope.row.escortInfo ? scope.row.escortInfo : "--" }}</span>
            </template>
        </el-table-column>
        <el-table-column prop="arrivalDate" label="期望到站时间" width="200px"></el-table-column>
        <el-table-column prop="sourceName" label="液源" width="120px"></el-table-column>
        <el-table-column prop="listingPrice" label="挂牌价(元/吨)" width="120px">
            <template slot-scope="scope">
                {{ scope.row.listingPrice ? scope.row.listingPrice : '--' }}
            </template>
        </el-table-column>
        <el-table-column prop="weight" label="预估总质量(吨)" width="120px"></el-table-column>
        <el-table-column prop="estimationMoney" label="预估总金额(元)" width="120px"></el-table-column>
        <el-table-column prop="updateDate" label="提交审批时间" width="120px"></el-table-column>
        <el-table-column prop="createUser" label="提交人" width="120px"></el-table-column>
        <el-table-column v-if="sole==3" prop="checkStatusName" label="审核状态" width="120px"></el-table-column>
        <el-table-column v-if="sole==3" prop='auditDate' label="审核时间" width="200px"></el-table-column>
        <el-table-column v-if="sole==2" prop="updateUser" label="取消人" width="120px"></el-table-column>
        <el-table-column v-if="sole==2" prop='updateDate' label="取消时间" width="200px"></el-table-column>
        <el-table-column label="操作" width="200px" fixed="right">
            <template slot-scope="scope">
                <div style="display: flex">
                    <plan-edit v-if="scope.row.tardCheckStatus!=3" :plan-id="scope.row.planId"
                               @plan-list="updateRoleList" :sole="sole"></plan-edit>
                    <div v-if="scope.row.tardCheckStatus==3&&sole==3">--</div>
                    <div style="marginLeft: 15px" v-if="sole==1">
                        <plan-cancel :role-id="scope.row.planId" @plan-list="updateRoleList"/>
                    </div>
                </div>
            </template>
        </el-table-column>
    </el-table>

</template>

<script>
import PlanCancel from './PlanCancel.vue';
import PlanEdit from './PlanEdit.vue';

export default {
    name: "PlanTable",
    props: {
        planList: {
            type: Array,
            default() {
                return []
            }
        },
        sole: {
            type: Number | String,
            default() {
                return []
            }
        }
    },
    components: {
        PlanEdit,
        PlanCancel

    },
    watch: {
        planList: {
            deep: true,  // 深度监听
            handler(newVal, oldVal) {
                this.$refs.table.doLayout()
            }
        }
    },
    methods: {
        // 监听子组件中发出的事件，重新获取角色列表
        updateRoleList() {
            console.log(586);
            this.$emit('plan-list');
        }
    }
}
</script>

<style scoped>

</style>
