/*
 * @Description:
 * @Author:
 * @Date: 2023-03-15 15:30:18
 * @LastEditors: Please set LastEditors
 * @LastEditTime: 2023-09-18 10:17:43
 */
import {request} from "./request";

// 获取订单列表
export function getOrderListRequest(params) {
  return request({
    url: '/trade/purchaseorder/list',
    params
  })
}

export function getOrderListOneRequest(params) {
  return request({
    url: '/trade/liquidPlant/list',
    params
  })
}

// 更新订单
export function updateOrderRequest(params) {
  return request ({
    url: '/trade/order/updateOrder',
    method: 'post',
    data:params
  })
}

// 取消订单
export function cancelOrderRequest (params) {
  return request ({
    url: `/trade/order/cancellation/order/${params.id}`,
    method: 'post',
    data: params
  })
}


// 修改订单
export function editOrderOneRequest(params){
  return request({
    url: '/trade/liquidPlant/edit',
    method: 'put',
    data: params
  })
}

// 修改订单
export function editOrderOnesRequest(params){
  return request({
    url: '/trade/liquidPlant/edits',
    method: 'put',
    data: params
  })
}

// 获取内采外采订单列表
export function getInternalListRequest(params) {
  return request({
    url: '/trade/order/lists',
    params
  })
}

export function editInternalListRequest(params){
  return request ({
    url: '/trade/order/updateUnload',
    method: 'post',
    data:params
  })
}

export function editMoneyRequest(params){
  return request ({
    url: '/trade/order/updateMoney',
    method: 'post',
    data:params
  })
}

// 导出外采和内采订单

export function ExportRequest(params){
  return request ({
    url: '/trade/order/exportExcel',
    method: 'get',
    responseType: "blob",
    params
  })
}

// 购液企业订单导出
export function ExportExcelRequest(params){
  return request ({
    url: '/trade/purchaseorder/exportExcel',
    method: 'get',
    responseType: "blob",
    params
  })
}



// 修改订单
export function editOrderRequest(params){
  return request({
    url: '/trade/purchaseorder/edit',
    method: 'put',
    data: params
  })
}

// 获取牵引车列表
export function getTractorRequest(params){
  return request ({
    url: '/trade/tractor/getTractor',
    method: 'post',
    data: params
  })
}

// 获取挂车列表
export function getTrailerRequest(params){
  return request ({
    url: '/trade/trailer/getTrailer',
    method: 'post',
    data: params
  })
}

// 获取司机列表
export function getDriverRequest(params){
  return request ({
    url: '/trade/driver/getDriver',
    method: 'post',
    data: params
  })
}

// 获取押运员列表
export function getEscortRequest(params){
  return request ({
    url: '/trade/escort/getEscort',
    method: 'post',
    data: params
  })
}

// 获取运输公司
export function getCompanyRequest(){
  return request ({
    url: '/trade/transportation/getTransportation',
    method: 'post'
  })
}

export function transportRequest(params){
  return request ({
    url: '/trade/order/transport',
    method: 'put',
    data:params
  })
}


export function transportOneRequest(params){
  return request ({
    url: '/trade/purchaseorder/transport',
    method: 'put',
    data:params
  })
}

// 获取物流进度
export function getOrderDetailRequest(id) {
  return request({
    url: `/orders/${id}`
  })
}
